<template>
  <Wysiwyg
    class="wysiwyg-wrapper"
    :content="content"
    :small-text="smallText"
    :no-container-padding="noContainerPadding"
    :filter-elements="filterElements"
  />
</template>
<script setup lang="ts">
import type { HTMLElementSimplified } from './types';

defineProps({
  content: {
    type: String,
    required: true,
    default: '',
  },
  smallText: {
    type: Boolean,
    required: false,
    default: false,
  },
  noContainerPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
  filterElements: {
    type: Function as PropType<
      (content: HTMLElementSimplified[]) => HTMLElementSimplified[]
    > | null,
    required: false,
    default: null,
  },
});

const Wysiwyg = defineAsyncComponent(() => {
  if (useSiteIdent() === SiteIdent.default) {
    return import('./vdv/wysiwyg.vue');
  } else {
    return import('./minilu/wysiwyg.vue');
  }
});
</script>

<style scoped lang="postcss">
.wysiwyg {
  :deep(figure) {
    @apply !max-w-full;
  }
}
</style>
